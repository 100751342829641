import React from "react"
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	makeStyles,
	TextField,
	Typography
} from "@material-ui/core"
import { GdprAnchor } from "../Gdpr"



export interface ContactFormProps {

}


export const ContactForm = (props: ContactFormProps) => {
	const classes = useStyles();
	return (
		<form method="post" action="/api/contact">

			<TextField
				name="name"
				required

				label="Vaše meno"
				variant="outlined"
				margin="normal"
				fullWidth
			/>

			<TextField
				name="email"
				required

				label="E-mail"
				type="email"
				variant="outlined"
				margin="normal"
				fullWidth
			/>
			
			<TextField
				name="web"
				
				label="Web"
				type="text"
				variant="outlined"
				margin="normal"
				fullWidth
				autoComplete="off"
				tabIndex={-1}
				className={classes.honeypot}
			/>

			<TextField
				name="fax"
				
				label="Fax"
				type="tel"
				variant="outlined"
				margin="normal"
				fullWidth
				autoComplete="off"
				tabIndex={-1}
				className={classes.honeypot}
			/>

			<TextField
				name="subject"
				required

				label="Predmet"
				variant="outlined"
				margin="normal"
				fullWidth
			/>

			<TextField
				name="message"
				required

				label="Správa"
				variant="outlined"
				multiline
				margin="normal"
				rows={5}
				rowsMax={20}
				fullWidth
			/>

			<FormControlLabel
				value=""
				control={(
					<Checkbox name="gdpr_agreement" value="1" color="primary" required />
				)}
				label={(
					<Typography variant="inherit">
						{'súhlasím so '}
						<GdprAnchor>spracovaním osobných údajov na{'\u00A0'}kontaktné účely</GdprAnchor>
					</Typography>
				)}
				labelPlacement="end"
			/>
			
			<FormControl margin={"normal"} error={true} className={classes.honeypot}>
				<FormControlLabel
					control={(
						<Checkbox name="user_agreement" value="1" color="primary" tabIndex={-1} inputProps={{autoComplete: "off"}} />
					)}
					label={'Som Robot'}
					labelPlacement="end"
					tabIndex={-1}
				/>
			</FormControl>

			<Box marginTop={2}>
				<Button type="submit" variant="contained" color="primary" size="large">Odoslať</Button>
			</Box>
		</form>
	);
};

const useStyles = makeStyles(theme => ({
	honeypot: {
		display: 'none',
	}
}));
