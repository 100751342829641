import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/layout.tsx";
import { Box } from "@material-ui/core";
import { ContactForm } from "../../components/ContactForm";
import { ContentSidePanelContact } from "../../components/Content";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Content = makeShortcode("Content");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Content side={<ContentSidePanelContact mdxType="ContentSidePanelContact" />} mdxType="Content">
      <h2>{`Kontaktný Formulár`}</h2>
      <Box mt={1} mdxType="Box">
	<ContactForm mdxType="ContactForm" />
      </Box>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      